import React from 'react';
import history from './common/history';
import { Router } from 'react-router-dom';
import Routes from './Routes';
import Firebase, {FirebaseContext} from './services/Firebase';

function App() {
  return (
    <FirebaseContext.Provider value={new Firebase()}>
      <Router history={history}>
        <Routes />
      </Router>
    </FirebaseContext.Provider>
  );
}

export default App;
