import React from 'react';
import './style.css';
import history from '../../common/history';

function PagenotFound() {

    const GoBack = () => {
        history.goBack();
    }

    return (
        <div id="notfound">
            <div className="notfound">
                <div className="notfound-404">
                    <h3>Oops! Page not found</h3>
                    <h1><span>4</span><span>0</span><span>4</span></h1>
                </div>
                <h2>we are sorry, but the page you requested was not found</h2>
                <h5 className="notfound-btn" onClick={GoBack}><i className="fa fa-arrow-left" aria-hidden="true"></i> Go Back</h5>
            </div>
            
        </div>
    )
}

export default PagenotFound
