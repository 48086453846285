import React, { useState } from 'react'
import star from "../../assets/images/star.png";
import arrow_1 from "../../assets/images/Arrow_1.svg";
import arrow_2 from "../../assets/images/Arrow_2.svg";

function Testimonial() {

    const data = [{
        name: "Helen",
        msg : "As ipsum ex, dignissim lobortis euismod quis, finibus fringilla quam. Phasellus sit amet lorem quam. Duis quis fringilla lacus."+
        "Maecenas eu augue aliquam, eleifend lorem quis, finibus arcu. Pellentesque convallis at urna placerat pellentesque. Donec eu volutpat"+
        "massa. Donec ac mi cursus, eleifend ligula eu, congue lectus. Cras rhoncus gravida viverra."
    },{
        name: "Alen",
        msg :  "Phasellus sit amet lorem quam.Duis quis fringilla lacus. Maecenas eu augue aliquam, eleifend lorem quis, finibus arcu.Ellentesque convallis at urna placerat pellentesque."
    },
    {
        name: "Test-1",
        msg : "Lorem ipsum dolor sit amet consectetur adipisicing elit. A facilis ipsa aliquid ratione, velit doloribus."
    },
    {
        name: "Test-2",
        msg : "Lorem ipsum dolor sit amet consectetur adipisicing elit. Numquam ad accusamus nobis explicabo sequi asperiores eligendi dicta. Praesentium assumenda quaerat repudiandae! Debitis assumenda eveniet nam?"
    },
    {
        name: "Test-3",
        msg : "Lorem ipsum dolor sit amet consectetur adipisicing elit."
    },
    {
        name: "ABC",
        msg : "Lorem ipsum dolor sit amet consectetur adipisicing elit. A facilis ipsa aliquid ratione, velit doloribus."
    }
]

    const [testimonial, setTestimonial] = useState(data);
    const [slideIndex, setSlideIndex] = useState(0);
    const [activeindex, setactiveindex] = useState(0);

    const goPrev = () => {
        if(slideIndex > 0){
        setSlideIndex(slideIndex-2);
        setactiveindex(activeindex-1);}
    };

    const goNext = () => {
        console.log(testimonial.length/2);
        if(slideIndex < testimonial.length/2){
            setactiveindex(activeindex+1);
        setSlideIndex(slideIndex+2);}
    };

    return (
        <div className="student-love-us">
                    <div className="container">
                        <h2 className="title">Students love us</h2>
                        <div className="student-testimonial-wrapper">

                            {testimonial.map((data,index)=>{
                                if(index >= slideIndex && index < slideIndex+2){
                                return (<>
                                {index%2===0 ? <div className="student-testi-left" data-aos="zoom-in">
                                <p>
                                    {data.msg}
                                </p>
                                <div className="student-name">
                                    <h6> {data.name}  |  Excellent</h6>
                                    <span>
                                        <img src={star} alt="Rating " />
                                        <img src={star} alt="Rating " />
                                        <img src={star} alt="Rating " />
                                        <img src={star} alt="Rating " />
                                        <img src={star} alt="Rating " />
                                    </span>
                                </div>
                            </div> : null }

                            {index%2!==0 ? <div className="student-testi-right" data-aos="zoom-in">
                                <p> {data.msg}
                                </p>
                                <div className="student-name">
                                    <h6> {data.name} |  Excellent</h6>
                                    <span>
                                        <img src={star} alt="Rating " />
                                        <img src={star} alt="Rating " />
                                        <img src={star} alt="Rating " />
                                        <img src={star} alt="Rating " />
                                        <img src={star} alt="Rating " />
                                    </span>
                                </div>
                            </div> : null }
                                </>)};
                            })}

                        </div>
                        <div className="testimonial-btn">
                            <button onClick={goPrev}> 
                                <img src={arrow_1} alt="A"/>
                            </button>
                            <div className="pagination">
                            {testimonial.map((data,index)=>{
                                if(index < testimonial.length/2){
                                return ( <>
                                <span className= { activeindex === index ? "dot active" : "dot" }>  </span>
                                </>)}
                                })
                            }
                            </div>
                            <button onClick={goNext}> 
                                <img src={arrow_2} alt="A"/>
                            </button>
                        </div> 
                    </div>
                </div>
    )
}

export default Testimonial