import React from 'react';
import './style.css';

//img 
import pdf from "../../assets/images/pdf-white.png";
import chart from "../../assets/images/chart.jpg";
import arrow from "../../assets/images/filter-arrow.png";
import devices from "../../assets/images/devices.png";

function Data() {
    return (
        <>
            <section id="section">
                <div className="data ">
                    <div className="container">
                        <div className="data-wrapper">
                            <div className="data-block">
                                <h2 className="title">Data Comparison Charts</h2>
                                <p>Data comparison charts based on student achievement in mathematics with different districts in the state of Mississippi</p>
                                <form method="post">
                                    <div className="data-filter">
                                        <div className="filter-block">
                                            <label>district</label>
                                            <select className="sel">
                                                <option>West Jackson +2</option>
                                                <option>Los Angelas</option>
                                                <option>London</option>
                                                <option>Las Vegas</option>
                                            </select>
                                        </div>
                                        <div className="filter-block">
                                            <label>Period</label>
                                            <select className="sel">
                                                <option>Last 6 Months</option>
                                                <option>Last 3 Months</option>
                                                <option>Last Month</option>
                                                <option>Custom Date</option>
                                            </select>
                                        </div>
                                        <div className="filter-block">
                                            <label>methodology</label>
                                            <select className="sel">
                                                <option>Not Selected</option>
                                                <option>Unspecify</option>
                                            </select>
                                        </div>
                                        <div className="filter-block">
                                            <button className="btn btn-secondary white-bg-btn">
                                                <span className="pdf-image"><img src={pdf} /></span>
                                                <span>Export</span>
                                            </button>
                                        </div>
                                    </div>
                                </form>
                                <div className="chart-desc">
                                    <h4>Data Comparison Charts: </h4>
                                    <p>West Jackson, Brookhaven, East Jackson, <b>Feb 2020 - July 2020</b></p>
                                </div>
                                <div className="chart-image">
                                    <img src={chart} alt="Chart Image" />
                                </div>
                                <div className="chart-detail">
                                    <h4>Details</h4>
                                    <div className="detail-table">
                                        <div className="row">
                                            <h5>district</h5>
                                            <h5 className="mobile-hide">progress</h5>
                                            <h5 className="mobile-hide">New/total schools</h5>
                                            <h5 className="mobile-hide">all schools</h5>
                                            <h5 />
                                        </div>
                                        <div className="content-row">
                                            <div className="row">
                                                <ul>
                                                    <li>West Jackson</li>
                                                    <li className="mobile-hide"><span><span className="fill" style={{ width: '18%' }} /></span> 18%</li>
                                                    <li className="mobile-hide">3 / <span className="outof">7</span></li>
                                                    <li className="mobile-hide">21</li>
                                                    <li><img src={arrow} alt="Image Arrow" /></li>
                                                </ul>
                                            </div>
                                            <div className="row selected">
                                                <ul>
                                                    <li>Brookhaven</li>
                                                    <li className="mobile-hide"><span><span className="fill" style={{ width: '64%' }} /></span>64%</li>
                                                    <li className="mobile-hide">0 / <span className="outof">11</span></li>
                                                    <li className="mobile-hide">48</li>
                                                    <li><img src={arrow} alt="Image Arrow" /></li>
                                                </ul>
                                                <div className="detail-box">
                                                    <div className="mobile-show flex-column">
                                                        <h5 className="mobile-progress">progress</h5>
                                                        <div className="mobile-dis">
                                                            <span className="mobile-pro"><span className="fill" style={{ width: '64%' }} /></span>64%
                      </div>
                                                    </div>
                                                    <div className="detail-left">
                                                        <ul>
                                                            <li>
                                                                <h4>Jul 2020</h4>
                                                                <span className="inner-progress">
                                                                    <span className="fill-green" style={{ width: '64%' }}>
                                                                        <span className="fill-blue" style={{ width: '64%' }} />
                                                                    </span>
                                                                </span>
                                                                <h4>64%</h4>
                                                            </li>
                                                            <li>
                                                                <h4>Jun 2020</h4>
                                                                <span className="inner-progress">
                                                                    <span className="fill-green" style={{ width: '12%' }}>
                                                                        <span className="fill-blue" style={{ width: '12%' }} />
                                                                    </span>
                                                                </span>
                                                                <h4>12%</h4>
                                                            </li>
                                                            <li>
                                                                <h4>May 2020</h4>
                                                                <span className="inner-progress">
                                                                    <span className="fill-green" style={{ width: '23%' }}>
                                                                        <span className="fill-blue" style={{ width: '23%' }} />
                                                                    </span>
                                                                </span>
                                                                <h4>23%</h4>
                                                            </li>
                                                            <li>
                                                                <h4>Apr 2020</h4>
                                                                <span className="inner-progress">
                                                                    <span className="fill-green" style={{ width: '64%' }}>
                                                                        <span className="fill-blue" style={{ width: '64%' }} />
                                                                    </span>
                                                                </span>
                                                                <h4>64%</h4>
                                                            </li>
                                                            <li>
                                                                <h4>Mar 2020</h4>
                                                                <span className="inner-progress">
                                                                    <span className="fill-green" style={{ width: '12%' }}>
                                                                        <span className="fill-blue" style={{ width: '12%' }} />
                                                                    </span>
                                                                </span>
                                                                <h4>12%</h4>
                                                            </li>
                                                            <li>
                                                                <h4>Feb 2020</h4>
                                                                <span className="inner-progress">
                                                                    <span className="fill-green" style={{ width: '23%' }}>
                                                                        <span className="fill-blue" style={{ width: '23%' }} />
                                                                    </span>
                                                                </span>
                                                                <h4>23%</h4>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div className="mobile-show">
                                                        <h5 className="mobile-progress">New/total schools</h5>
                                                        <div className="mobile-progress">0 / <span className="outof">11</span></div>
                                                    </div>
                                                    <div className="mobile-show">
                                                        <h5 className="mobile-progress">all schools</h5>
                                                        <div className="mobile-no">48</div>
                                                    </div>
                                                    <div className="detail-right">
                                                        <span>
                                                            <img src={devices} alt="Chart Image" />
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <ul>
                                                    <li>East Jackson</li>
                                                    <li className="mobile-hide"><span><span className="fill" style={{ width: '83%' }} /></span> 83%</li>
                                                    <li className="mobile-hide">3 / <span className="outof">7</span></li>
                                                    <li className="mobile-hide">21</li>
                                                    <li><img src={arrow} alt="Image Arrow" /></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="before-footer">
                    <div className="container">
                        <div className="before-footer-inner">
                            <h2 className="title title-white">Contact Us</h2>
                            <a href="#" className="btn btn-primary">Start the Demo</a>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Data
