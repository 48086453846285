import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/functions';

const firebaseConfig = {
    apiKey: "AIzaSyCsPnLUjCNoKN0zDvGXE_cTuxdvkocOkLc",
    authDomain: "rlmackconsulting.firebaseapp.com",
    databaseURL: "https://rlmackconsulting.firebaseio.com",
    projectId: "rlmackconsulting",
    storageBucket: "rlmackconsulting.appspot.com",
    messagingSenderId: "685269512805",
    appId: "1:685269512805:web:95788e825192ace1b2cc8c",
    measurementId: "G-GB99LGGT1J"
};

class Firebase {
    authUser;
    constructor() {
        app.initializeApp(firebaseConfig);
        this.auth = app.auth();
        this.db = app.firestore();
        this.storage = app.storage();
        this.functions = app.functions();
    }

    dosignIn = (email,password) => this.auth.signInWithEmailAndPassword(email,password);

    doPasswordReset = email => this.auth.sendPasswordResetEmail(email);

    onAuthUserListener = (next, fallback) =>
      this.auth.onAuthStateChanged((user)=>{
        if (user) {
            next(user);
        } else {
            fallback();
        }
      });
    

    dosignOut = async () => await this.auth.signOut();

};

export class FirebaseApp {
    constructor() {
        var app2 = app.apps.length !==2 ? app.initializeApp(firebaseConfig,"Secondary") : app.app();
        this.auth = app2.auth();
        this.db = app2.firestore();
        this.storage = app2.storage();
        this.functions = app2.functions();
    }
}

export default Firebase;