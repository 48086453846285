import React from 'react'

function Footer() {
	return (
		<>
			<footer id="footer">
				<div className="container">
					<div className="footer-inner">
						<div className="footer-left">
							<ul>
								<li>© 2020 All Rights Reserve by &nbsp; <a href="/"> RLMacksConsulting</a> </li>
								<li><a href="/">Terms &amp; Conditions</a></li>
								<li><a href="/">Privacy Statement</a></li>
							</ul>
						</div>
						{/* <div className="footer-right">
							<ul className="social-media">
								<li><a href="/"><i className="fa fa-facebook" aria-hidden="true" /></a></li>
								<li><a href="/"><i className="fa fa-linkedin" aria-hidden="true" /></a></li>
								<li><a href="/"><i className="fa fa-instagram" aria-hidden="true" /></a></li>
								<li><a href="/"><i className="fa fa-youtube" aria-hidden="true" /></a></li>
								<li><a href="/"><i className="fa fa-twitter" aria-hidden="true" /></a></li>
							</ul>
						</div> */}
					</div>
				</div>
			</footer>

		</>
	)
}

export default Footer
