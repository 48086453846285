import React, { useEffect } from 'react'
import AOS from 'aos';
import './style.css';

//img
import tutorial1 from "../../assets/images/resources/tutorial-1.jpg";
import tutorial2 from "../../assets/images/resources/tutorial-2.jpg";
import tutorial3 from "../../assets/images/resources/tutorial-3.jpg";
import tutorial4 from "../../assets/images/resources/tutorial-4.jpg";
import tutorial5 from "../../assets/images/resources/tutorial-5.jpg";
import playicon from "../../assets/images/play-icon.png";
import { Link } from 'react-router-dom';

function Resources() {
    useEffect(() => {
        AOS.init();
      }, []);
    return (
        <>
            <section id="section">
                <div className="content-block-1 con resource ">
                    <div className="container">
                        <div className="contact-wrapper">
                            <div className="contact-block">
                                <h2 className="title">Knowledge Base</h2>
                                <div className="tutorial-block-wrapper">
                                    <div className="tutorial-block">
                                        <div className="right-side-bullet">
                                            <ul>
                                                <li data-aos="fade-left"><Link to="/pdf-view">PDF Cards</Link></li>
                                                <li data-aos="fade-left"><Link to="/pdf-view">International business class</Link></li>
                                                <li data-aos="fade-left"><Link to="/pdf-view">Practical school</Link></li>
                                                <li data-aos="fade-left"><Link to="/pdf-view">Training</Link></li>
                                                <li data-aos="fade-left"><Link to="/pdf-view">Practical school</Link></li>
                                                <li data-aos="fade-left"><Link to="/pdf-view">International business class</Link></li>
                                                <li data-aos="fade-left"><Link to="/pdf-view">Practical school</Link></li>
                                                <li data-aos="fade-left"><Link to="/pdf-view">Training</Link></li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="tutorial-block">
                                        <div className="right-side-bullet">
                                            <ul>
                                                <li data-aos="fade-left"><Link to="/pdf-view">PDF Cards</Link></li>
                                                <li data-aos="fade-left"><Link to="/pdf-view">International business class</Link></li>
                                                <li data-aos="fade-left"><Link to="/pdf-view">Practical school</Link></li>
                                                <li data-aos="fade-left"><Link to="/pdf-view">Training</Link></li>
                                                <li data-aos="fade-left"><Link to="/pdf-view">Practical school</Link></li>
                                                <li data-aos="fade-left"><Link to="/pdf-view">International business class</Link></li>
                                                <li data-aos="fade-left"><Link to="/pdf-view">Practical school</Link></li>
                                                <li data-aos="fade-left"><Link to="/pdf-view">Training</Link></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="tutorial-video">
                    <div className="container">
                        <div className="tutorial-video-wrapper">
                            <h2 className="title">Video Tutorials</h2>
                            <div className="video-block-wrapper">
                                <div className="video-block">
                                    <div className="image-block">
                                        <span><img src={tutorial1} alt="Video Thumbnail" /></span>
                                        <Link  className="play"><img src={playicon} alt="Play Icon" /></Link>
                                    </div>
                                    <div className="video-title">
                                        <h4><Link >A video message from founder R.L.Mack</Link></h4>
                                        <h5>12:11</h5>
                                    </div>
                                </div>
                                <div className="video-block">
                                    <div className="image-block">
                                        <span><img src={tutorial2} alt="Video Thumbnail" /></span>
                                        <Link className="play"><img src={playicon} alt="Play Icon" /></Link>
                                    </div>
                                    <div className="video-title">
                                        <h4><Link >A video message from founder R.L.Mack</Link></h4>
                                        <h5>12:11</h5>
                                    </div>
                                </div>
                                <div className="video-block">
                                    <div className="image-block">
                                        <span><img src={tutorial3} alt="Video Thumbnail" /></span>
                                        <Link className="play"><img src={playicon} alt="Play Icon" /></Link>
                                    </div>
                                    <div className="video-title">
                                        <h4><Link >A video message from founder R.L.Mack</Link></h4>
                                        <h5>12:11</h5>
                                    </div>
                                </div>
                                <div className="video-block">
                                    <div className="image-block">
                                        <span><img src={tutorial4} alt="Video Thumbnail" /></span>
                                        <Link  className="play"><img src={playicon} alt="Play Icon" /></Link>
                                    </div>
                                    <div className="video-title">
                                        <h4><Link>A video message from founder R.L.Mack</Link></h4>
                                        <h5>12:11</h5>
                                    </div>
                                </div>
                                <div className="video-block">
                                    <div className="image-block">
                                        <span><img src={tutorial5} alt="Video Thumbnail" /></span>
                                        <Link className="play"><img src={playicon} alt="Play Icon" /></Link>
                                    </div>
                                    <div className="video-title">
                                        <h4><Link >A video message from founder R.L.Mack</Link></h4>
                                        <h5>12:11</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="before-footer">
                    <div className="container">
                        <div className="before-footer-inner">
                            <h2 className="title title-white">Contact Us</h2>
                            <Link className="btn btn-primary">Contact Us</Link>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default Resources
