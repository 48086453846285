import React from "react";
import { Switch, Redirect } from "react-router-dom";
import RouteWithLayout from "../components/Route/RouteWithLayout";

import LandingLayout from "../components/Layout/LandingLayout";

// Views
import Home from "../views/Home/Home";
import Login from "../views/Login/Login";
import Data from "../views/Data/Data";
import Contact from "../views/Contact/Contact";
import PagenotFound from "../views/Error/PagenotFound";
import Resources from "../views/Resources/Resources";
import AdminLayout from "../components/Layout/AdminLayout";
import PdfView from "../views/Resources/PdfView";


const Routes = (props) => {
  return (
    <Switch>
      <RouteWithLayout
        path="/"
        exact
        layout={LandingLayout}
        component={Home}
      />
      <RouteWithLayout
        path="/resources"
        exact
        layout={LandingLayout}
        component={Resources}
      />
      <RouteWithLayout
        path="/data"
        exact
        layout={LandingLayout}
        component={Data}
      />
      <RouteWithLayout
        path="/contact-us"
        exact
        layout={LandingLayout}
        component={Contact}
      />
      <RouteWithLayout
        path="/login"
        exact
        layout={LandingLayout}
        component={Login}
      />
      <RouteWithLayout
        path="/not-found"
        exact
        layout={LandingLayout}
        component={PagenotFound}
      />
      <RouteWithLayout
        path="/admin"
        exact
        layout={AdminLayout}
        component={Resources}
      />
      <RouteWithLayout
        path="/pdf-view"
        exact
        layout={LandingLayout}
        component={PdfView}
      />
      
      
      <Redirect from="*" to="/not-found" />
    </Switch>
  );
};

export default Routes;
