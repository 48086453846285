import React from 'react'
import Header from './Header'
import Footer from './Footer'
function LandingLayout(props) {
    const { children } = props;

    return (
        <div className="wrapper">  
             <Header/>
                {children}
             <Footer/>
        </div>
    )
}

export default LandingLayout
