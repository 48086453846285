import { useFormik } from 'formik';
import { withFirebase } from '../../services/Firebase';
import React, { useState } from 'react'
import { Alert } from 'evergreen-ui';
import history from '../../common/history';

function LoginForm(props) {
    const { firebase } = props;
    const { handleForgotPassword } = props

    const [loginError, setLoginError] = useState(null)
    const LoginForm = useFormik({
        initialValues: {
            txtEmail: "",
            txtPassword: "",
        },
        onSubmit: values => {
            handleSignIn(values);
        }
    })

    const handleSignIn = async (data) => {
        await firebase.dosignIn(data.txtEmail, data.txtPassword).then(async (data) => {
            history.push('/admin');
        }).catch(error => {
                if (error.code) {
                    console.log(error);
                    setLoginError(error);
                } else {
                    setLoginError(null);
                }
            })
    }

    let { handleSubmit, values, handleChange, handleBlur } = LoginForm;
    return (
        <div className="login-left">
            <h2 className="title">Login</h2>
            {loginError ?
                <Alert style={{ marginBottom: 20 }}
                    intent="danger"
                    title={loginError.message}
                /> : null}
            <form onSubmit={handleSubmit}>
                <div className="form-field-wrapper">

                    <div className="form-field full">
                        <label>Email Address</label>
                        <input type="email" name="txtEmail" placeholder="you@example"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.txtEmail}
                        />
                    </div>
                    <div className="form-field full">
                        <label className="forgot"><span>Password</span>
                        <a onClick={()=>handleForgotPassword(true)}>Forgot password?</a>
                        </label>
                        <input type="password" name="txtPassword" placeholder="enter the password"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.txtPassword}
                        />
                    </div>

                    <div className="btn-wrapper">
                        <button type="submit" name="btn-send" className="btn btn-primary white-bg-btn">Login</button>
                    </div>

                    <div className="signup-link" >
                      <span onClick={()=>history.push("/contact-us")} >  Need an Account ? </span> 
                    </div>
                </div>
            </form>
        </div>
    )
}

export default withFirebase(LoginForm);
