import React, { useState } from 'react'
import { withFirebase } from '../../services/Firebase';
import { useFormik } from 'formik';
import CustomSweetAlert from '../../components/generic/CustomSweetAlert';

function ForgotPassword(props) {
    const { handleForgotPassword, firebase } = props
    const [SweetAlert, setSweetAlert] = useState({
        show: false,
        type: "",
        onConfirm: ()=> {setSweetAlert({...setSweetAlert,show:false})},
        onCancel: ()=> {setSweetAlert({...setSweetAlert,show:false})}
    });
    const ForgotPasswordForm = useFormik({
        initialValues: {
            txtEmail: ""
        },
        onSubmit: values => {
            console.log(values);
            onPasswordReset(values.txtEmail);
        }
    })

    const onPasswordReset = async (email) => {
        await firebase.auth.sendPasswordResetEmail(email).then(function () {
            console.log("Password reset email sent.");
            setSweetAlert({
                show: true,
                type: "success",
                message: "A reset password link has been sent to your email",
                onConfirm: ()=> {setSweetAlert({...setSweetAlert,show:false})},
                onCancel: ()=> {setSweetAlert({...setSweetAlert,show:false})}
            })
        setTimeout(() => {
            handleForgotPassword(false);    
        }, 2500);
        }).catch(function (error) {
            console.log(error);
            setSweetAlert({
                show: true,
                type: "warning",
                message: error.message,
                onConfirm: ()=> {setSweetAlert({...setSweetAlert,show:false})},
                onCancel: ()=> {setSweetAlert({...setSweetAlert,show:false})}
            })
        });
    }

    let { handleSubmit, values, handleChange, handleBlur } = ForgotPasswordForm;
    return (
        <div className="login-left">
            <h2 className="title">Forgot Password !</h2>

            <form onSubmit={handleSubmit}>
                <div className="form-field-wrapper">

                    <div className="form-field full">
                        <label>Email Address</label>
                        <input type="email" name="txtEmail"
                            placeholder="Enter Your Email Address"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.txtEmail}
                        />
                    </div>

                    <div className="btn-wrapper">
                        <button type="submit" name="btn-send" className="btn btn-primary white-bg-btn">Reset</button>
                    </div>

                    <div style={{ marginTop: "60px", cursor: "pointer" }}
                        onClick={() => handleForgotPassword(false)}
                    >
                        Login Here.
                    </div>
                </div>
            </form>
            <CustomSweetAlert {...SweetAlert}/>
        </div>
    )
}

export default withFirebase(ForgotPassword);
