import React from 'react';
import "./style.css";

//images
import phone from "../../assets/images/phone.png";
import email from "../../assets/images/email.png";
import location from "../../assets/images/location.png";
import ContactForm from './ContactForm';

function Contact() {
    return (
        <>
            <section id="section">
                <div className="content-block-1 con">
                    <div className="container">
                        <div className="contact-wrapper">
                            <div className="contact-block">
                                <h2 className="title">Contact Us</h2>
                                <p>We take our commitment to our users seriously.</p>
                                <p>If you need out help, have questions about how to use the cards or experience
                                    technical difficulties,please do not hesitate to contact us.</p>
                            </div>
                            <div className="contact-block-bottom">
                                <div className="contact-left">
                                    <ul>
                                        <li><img src={phone} alt="Phone" /> <span>(662) 735-1088</span></li>
                                        <li><img src={email} alt="email" /> <span><a href="/">support@rlmack.com</a></span></li>
                                        <li><img src={location} alt="location" /> <span>39 Lucky Dr <span>Waynesboro, Mississippi(MS), 39367</span></span></li>
                                    </ul>
                                </div>
                               
                               <ContactForm/>

                            </div>
                        </div>
                    </div>
                </div>
                <div className="before-footer">
                    <div className="container">
                        <div className="before-footer-inner">
                            <h2 className="title title-white">Contact Us</h2>
                            <a href="/" className="btn btn-primary">Contact Us</a>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default Contact
