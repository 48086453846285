import React, { useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import file from "./sample.pdf";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

function PdfView() {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [scale, setScale] = useState(1.5)
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const zoomIn = () => {
    if(scale < 5){
    setScale(scale + 0.5); }
  }

  const zoomOut = () => {
    if(scale > 1){
    setScale(scale - 0.5);}
  }

  const prvPage = () => {
    if(pageNumber > 1){
    setPageNumber(pageNumber-1);}
  }

  const nextPage = () => {
    if(pageNumber < numPages){
    setPageNumber(pageNumber+1);}
  }

  return (
    <>
      
      <div className="pdf-footer">
      
      <div className="pdf-page">
      <button onClick={prvPage}> <i class="fa fa-chevron-left" aria-hidden="true"></i> </button>
      <p>Page {pageNumber} of {numPages}</p>
      <button onClick={nextPage}> <i class="fa fa-chevron-right" aria-hidden="true"></i> </button>
      </div>

      <div className="pdf-zoom">
      <button onClick={zoomIn}> <i class="fa fa-search-plus" aria-hidden="true"></i> </button>
      <button onClick={zoomOut}> <i class="fa fa-search-minus" aria-hidden="true"></i> </button>
      </div>
      
      </div>
    <div className="pdf-view">
      <Document
        file={file}
        onLoadSuccess={onDocumentLoadSuccess}
        onLoadError={console.error}
      >
        <Page pageNumber={pageNumber} scale={scale} />
      </Document>
    </div>
    
    </>
  );
}

export default PdfView;