import React, { useEffect } from 'react'
import AOS from 'aos';

//images
import img1 from '../../assets/images/1.png';
import img2 from '../../assets/images/2.png';
import img3 from '../../assets/images/3.png';
import img4 from '../../assets/images/4.png';
import vidThumb from "../../assets/images/vid-thumb.png";
import playIcon from "../../assets/images/play-icon.png";
import service from "../../assets/images/service-image.png";
import pin from "../../assets/images/pin.png";
import banner from '../../assets/images/banner-img.jpg';
import { Link } from 'react-router-dom';
import Testimonial from './Testimonial';

function Home() {
    useEffect(() => {
        AOS.init();
      }, []);
    return (
        <>  
            <div className="container">
            <div className="header-banner">
                <div className="header-banner-left">
                    <div className="left-content">
                    <h4>For <u>every</u> student,every classroom.</h4>
                    <h3>Access <span>2500+ Resources</span> to improve the performance level</h3>
                    </div>
                </div>
                <div className="header-banner-right">
                    <img src={banner} alt="Banner" />
                </div>
            </div>
            </div>

            <section id="section">
                <div className="content-block-1">
                    <div className="container">
                        <div className="why-consuting">
                            <div className="why-consuting-top">
                                <h2 className="title">Why R.L.Mack Consulting?</h2>
                                <p>As ipsum ex, dignissim lobortis euismod quis, finibus fringilla quam. Phasellus sit amet lorem quam. Duis quis fringilla lacus. Maecenas eu augue aliquam, eleifend lorem quis, finibus arcu. Pellentesque convallis at urna placerat pellentesque. Donec eu volutpat massa. Donec ac mi cursus, eleifend ligula eu, congue lectus. Cras rhoncus gravida viverra.</p>
                                <p>Vivamus imperdiet justo sapien, vitae finibus neque facilisis consectetur. Pellentesque vulputate nisl nec velit commodo, eu placerat neque vehicula. Cras sem tortor, ultrices at tincidunt vel, condimentum rutrum metus.</p>
                            </div>
                            <div className="why-consuting-bottom">
                                <div className="consulting-box">
                                    <span data-aos="fade-down"><img src={img1} alt="" /></span>
                                    <h4 data-aos="fade-up">Remember everything</h4>
                                    <p data-aos="fade-up">Donec non elit sit amet mi pulvinar consectetur eu a justo. </p>
                                </div>
                                <div className="consulting-box">
                                    <span data-aos="fade-down"><img src={img2} alt="" /></span>
                                    <h4 data-aos="fade-up">Use what you learn</h4>
                                    <p data-aos="fade-up">Donec non elit sit amet mi pulvinar consectetur eu a justo. </p>
                                </div>
                                <div className="consulting-box">
                                    <span data-aos="fade-down"><img src={img3} alt="" /></span>
                                    <h4 data-aos="fade-up">Best programs</h4>
                                    <p data-aos="fade-up">Donec non elit sit amet mi pulvinar consectetur eu a justo. </p>
                                </div>
                                <div className="consulting-box">
                                    <span data-aos="fade-down"><img src={img4} alt="" /></span>
                                    <h4 data-aos="fade-up">Trusted content</h4>
                                    <p data-aos="fade-up">Donec non elit sit amet mi pulvinar consectetur eu a justo. </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="video-section">
                    <div className="container">
                        <div className="vid-inner">
                            <div className="video-sec-left" data-aos="fade-down">
                                <div className="inner-video">
                                    <img src={vidThumb} alt="" />
                                    <span><img src={playIcon} alt="" /></span>
                                </div>
                            </div>
                            <div className="video-sec-right" data-aos="fade-up">
                                <h4>A video message from founder R.L.Mack Consulting sharing more about what we are doing to help out schools to improve their performance level. </h4>
                                <p>Pellentesque finibus augue non massa ultrices, eu sagittis nulla tincidunt. Sed lacinia, mi et sodales scelerisque, magna tellus bibendum sapien, in efficitur lectus nisl in purus. </p>
                                <Link to="/contact-us" className="btn btn-primary">Contact Us</Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="service-sec">
                    <div className="container">
                        <div className="service-inner">
                            <div className="service-inner-left" data-aos="fade-right">
                                <span><img src={service} alt="" /></span>
                                <h2 className="title">Services</h2>
                                <p>View our other training courses and courses here.</p>
                            </div>
                            <div className="right-side-bullet">
                                <ul>
                                    <li data-aos="fade-right">Basic education</li>
                                    <li data-aos="fade-right">McMath Cards</li>
                                    <li data-aos="fade-right">International business class</li>
                                    <li data-aos="fade-right">Practical school</li>
                                    <li data-aos="fade-right">Training</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                
                <Testimonial/>

                <div className="service-sec">
                    <div className="container">
                        <div className="service-inner">
                            <div className="service-inner-left" data-aos="fade-right">
                                <span><img src={pin} alt="" /></span>
                                <h2 className="title">Resources</h2>
                                <p>View our other training courses and courses here.</p>
                                <div className="btn-wrapper">
                                    <Link to="/resources" className="btn btn-primary">See All</Link>
                                </div>
                            </div>
                            <div className="right-side-bullet">
                                <ul>
                                    <li data-aos="fade-right">Video education</li>
                                    <li data-aos="fade-right">PDF Cards</li>
                                    <li data-aos="fade-right">International business class</li>
                                    <li data-aos="fade-right">Practical school</li>
                                    <li data-aos="fade-right">Training</li>
                                    <li data-aos="fade-right">Practical school</li>
                                    <li data-aos="fade-right">International business class</li>
                                    <li data-aos="fade-right">Practical school</li>
                                    <li data-aos="fade-right">Training</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="before-footer">
                    <div className="container">
                        <div className="before-footer-inner">
                            <h2 className="title title-white">Contact Us</h2>
                            <Link to="/contact-us" className="btn btn-primary">Contact Us</Link>
                        </div>
                    </div>
                </div>
            </section>
            <script>
            AOS.init();
            </script>
        </>
    )
}

export default Home
