import { useFormik } from 'formik'
import React, { useState } from 'react'
import CustomSweetAlert from '../../components/generic/CustomSweetAlert';
import { withFirebase } from '../../services/Firebase';

function ContactForm(props) {
    const { firebase } = props;
    const [SweetAlert, setSweetAlert] = useState({
        show: false,
        type: "",
        onConfirm: ()=> {setSweetAlert({...setSweetAlert,show:false})},
        onCancel: ()=> {setSweetAlert({...setSweetAlert,show:false})}
    });
    const [btnTxt, setbtnTxt] = useState("Send Message")
    const [isDisabled, setisDisabled] = useState(false)
    const validate = values => {
        let errors = {};
        if (!values.txtName) {
            errors.txtName = true;
        }

        if (!values.txtEmail) {
            errors.txtEmail = true;
        }

        if (!values.txtPhone) {
            errors.txtPhone = true;
        }

        if (!values.txtMessage) {
            errors.txtMessage = true;
        }

        return errors;
    };

    const contactForm = useFormik({
        initialValues: {
            txtName: "",
            txtEmail: "",
            txtPhone: "",
            txtMessage: "",
        },
        onSubmit: values => {
            onSubmit(values);
        },
        validate
    })

    const onSubmit = async (Data) => {
        setbtnTxt("Sending...")
        setisDisabled(true);
        const mailData = {
            "name": Data.txtName,
            "email": Data.txtEmail,
            "phone": Data.txtPhone,
            "message": Data.txtMessage
        }
        console.log(mailData);
        const contactUsEmail = firebase.functions.httpsCallable('contactUsMail');
        try {
            await contactUsEmail(mailData).then(function (result) {
                console.log(result);
                setSweetAlert({
                    show: true,
                    type: "success",
                    title: "Thanks for contacting us.",
                    message: "One of our team members will reach out to you shortly.",
                    onConfirm: ()=> {setSweetAlert({...setSweetAlert,show:false})},
                    onCancel: ()=> {setSweetAlert({...setSweetAlert,show:false})}
                })
                setisDisabled(false);
                setbtnTxt("Send Message");
            });
        } catch (error) {
            console.log(error);
            setSweetAlert({
                show: true,
                type: "warning",
                message: error.message,
                onConfirm: ()=> {setSweetAlert({...setSweetAlert,show:false})},
                onCancel: ()=> {setSweetAlert({...setSweetAlert,show:false})}
            })
            setisDisabled(false);
            setbtnTxt("Send Message");
        } 
        console.log(Data);
        setbtnTxt("Send Message")
    }

    let { handleSubmit, errors, values, handleChange, handleBlur, touched } = contactForm;
    return (
        <div className="contact-right">
            <form onSubmit={handleSubmit}>
                <div className="form-field-wrapper">
                    <div className="form-field">
                        <label>Name
                    {touched.txtName && errors.txtName === true ?
                                <span style={{ color: "red" }}> &nbsp;* </span> : null}
                        </label>
                        <input type="text" name="txtName" placeholder="your name"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.txtName}
                        />
                    </div>
                    <div className="form-field">
                        <label>Email Address
                    {touched.txtEmail && errors.txtEmail === true ?
                                <span style={{ color: "red" }}> &nbsp;* </span> : null}
                        </label>
                        <input type="email" name="txtEmail" placeholder="you@example"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.txtEmail}
                        />
                    </div>
                </div>
                <div className="form-field-wrapper">
                    <div className="form-field">
                        <label>Phone
                    {touched.txtPhone && errors.txtPhone === true ?
                                <span style={{ color: "red" }}> &nbsp;* </span> : null}
                        </label>
                        <input type="tel" name="txtPhone" placeholder="contact phone"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.txtPhone}
                        />
                    </div>
                    <div className="form-field">
                        <label>Subject</label>
                        <select className="sub">
                            <option>Select</option>
                            <option>Subject 1</option>
                            <option>Subject 2</option>
                            <option>Subject 3</option>
                            <option>Subject 4</option>
                            <option>Subject 5</option>
                        </select>
                    </div>
                </div>
                <div className="form-field-wrapper">
                    <div className="form-field full">
                        <label>Message
                    {touched.txtMessage && errors.txtMessage === true ?
                                <span style={{ color: "red" }}> &nbsp;* </span> : null}
                        </label>
                        <textarea placeholder="type your text here" name="txtMessage"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.txtMessage}
                        />
                    </div>
                </div>
                <div className="btn-wrapper">
                    <button type="submit" name="btn-send" disabled={isDisabled}
                        className="btn btn-primary white-bg-btn"
                    > {btnTxt} </button>
                </div>
            </form>
            <CustomSweetAlert {...SweetAlert}/>
        </div>
    )
}

export default withFirebase(ContactForm);
