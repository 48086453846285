import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import history from '../../common/history';
import { AuthUserContext } from '../../Context';
import { withFirebase } from '../../services/Firebase';
import { useMediaQuery } from 'react-responsive'

//img
import menuImg from "../../assets/images/menu.png";
import menuclose from "../../assets/images/menu_close.png";
import logo from '../../assets/images/logo.png';

function Header(props) {
    const { firebase } = props;
    const Phone = useMediaQuery({ query: '(max-device-width: 991px)' })
    const [LoctionPath, setLoctionPath] = useState("/")
    const [phonemenu, setPhonemenu] = useState(false)
    const something = React.useContext(AuthUserContext);
    useEffect(() => {
        setLoctionPath(history.location.pathname);
    })

    const onLogout = () =>{
        firebase.dosignOut();
        history.push("/login");
    }

    const PhoneMenu = () => {
        if(phonemenu){
            setPhonemenu(false)
        }
        if(!phonemenu){
            setPhonemenu(true)
        }
    }
    return (
        <>
            <header id="header">
                <div className="container">
                    <div className="inner-header">
                        <div className="logo">
                            <a href="/"><img src={logo} alt="Logo" /></a>
                        </div>
                        <div className="mainmenu">
                            <span className="menu-btn" style={{display: Phone ? "block" :"none"}}
                                onClick={PhoneMenu}
                            >
                                <img src={ phonemenu ? menuclose : menuImg} alt="menu"/>
                            </span>
                            <ul className={phonemenu ? "show-menu phone-menu" : "phone-menu" } 
                                style={Phone ? {display:"none"} : null}>
                                <li className={LoctionPath === "/" ? "active" : ""} onClick={PhoneMenu}>
                                    <Link to="/">Home</Link>
                                </li>
                                <li className={LoctionPath === "/resources" ? "active" : ""} onClick={PhoneMenu}>
                                    <Link to="/resources">Resources</Link>
                                </li>
                                <li className={LoctionPath === "/data" ? "active" : ""} onClick={PhoneMenu}>
                                    <Link to="/data">Results</Link>
                                </li>
                                <li className={LoctionPath === "/contact-us" ? "active" : ""} onClick={PhoneMenu}>
                                    <Link to="/contact-us">Contact</Link>
                                </li>
                                {something ? 
                                <li>
                                    <Link onClick={onLogout}>Logout</Link>
                                </li> :
                                <li className={LoctionPath === "/login" ? "active" : ""} onClick={PhoneMenu}>
                                    <Link to="/login">Login</Link>
                                </li>
                                    }
                            </ul>
                        </div>
                    </div>
                </div>
            </header>

        </>
    )
}

export default withFirebase(Header);
