import React, { useEffect, useState } from 'react';
import Header from './Header';
import Footer from './Footer';
import { withFirebase } from '../../services/Firebase';
import history from '../../common/history'
import { AuthUserContext } from '../../Context';

function AdminLayout(props) {
    const { children, firebase } = props;
    const [user, setUser] = useState(null)
    useEffect(() => {
        onAuthUserListener();
    }, [])

    const onAuthUserListener = async () => {
        await firebase.auth.onAuthStateChanged((user) => {
            if (user) {
                console.log(user);
                setUser(user);
            } else {
                console.log("empty");
                history.push("/login");
            }
        });
    }

    return (
        <AuthUserContext.Provider value={user}>
            <div className="wrapper">
                <Header />
                {children}
                <Footer />
            </div>
        </AuthUserContext.Provider>
    )
}

export default withFirebase(AdminLayout);