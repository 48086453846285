import React, { useState } from 'react';
import "./style.css";

import star from "../../assets/images/star.png";
import LoginForm from './LoginForm';
import ForgotPassword from './ForgotPassword';

function Login() {
    const [isForgotPassword, setisForgotPassword] = useState(false)

    const handleForgotPassword = (value) =>{
        setisForgotPassword(value);
    }

    return (
        <>
            <section id="section">
                <div className="login-wrapper">
                    <div className="container">
                        <div className="login-block">
                            {isForgotPassword ? 
                            <ForgotPassword handleForgotPassword={handleForgotPassword}/>
                            : <LoginForm  handleForgotPassword={handleForgotPassword} /> }
                            
                            <div className="login-right">
                                <div className="student-testi-left">
                                    <span>
                                        <img src={star} alt="Rating" />
                                        <img src={star}  alt="Rating" />
                                        <img src={star}  alt="Rating" />
                                        <img src={star}  alt="Rating" />
                                        <img src={star}  alt="Rating" />
                                    </span>
                                    <p><span>As ipsum ex, dignissim lobortis euismod quis, finibus fringilla quam.</span>
              Phasellus sit amet lorem quam. Duis quis fringilla lacus. Maecenas eu augue aliquam, eleifend lorem quis, finibus arcu.
              Pellentesque convallis at urna placerat pellentesque. Donec eu volutpat massa. Donec ac mi cursus, eleifend ligula eu,
              congue lectus. Cras rhoncus gravida viverra.</p>
                                    <div className="student-name">
                                        <h6>Helen  |  Excellent</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default Login;
